<template>
    <div class="profile_notes">
        <div class="title">
            <h3>Total Rewards: 420</h3>
            <div class="right_side">
                <button type="button" class="add_btn mr-3" @click="redeem = true"><i class="fas fa-gift"></i>Redeem</button>
                <div class="search_panel mr-3">
                    <div class="form-field">
                        <input type="text" name="search" placeholder="Search...">
                    </div>
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <PageItem/>
                <button :class="deleteBox.length ? 'delete active' : 'delete'"><i class="fas fa-trash-alt"></i></button>
            </div>
        </div>            
        <ul class="card_listing">
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4>140</h4>
                    <p>Pellentesque rutrum lorem nec tincidunt sodales.</p>
                    <h5>Source:</h5>
                    <p>Contact Center</p>
                    <h5>Created on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                    <label for="reward1" class="checkbox">
                        <input type="checkbox" id="reward1" value="reward1" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4>130</h4>
                    <p>Pellentesque rutrum lorem nec tincidunt sodales.</p>
                    <h5>Source:</h5>
                    <p>Contact Center</p>
                    <h5>Created on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                    <label for="reward2" class="checkbox">
                        <input type="checkbox" id="reward2" value="reward2" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4>150</h4>
                    <p>Pellentesque rutrum lorem nec tincidunt sodales.</p>
                    <h5>Source:</h5>
                    <p>Contact Center</p>
                    <h5>Created on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                    <label for="reward3" class="checkbox">
                        <input type="checkbox" id="reward3" value="reward3" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4>140</h4>
                    <p>Pellentesque rutrum lorem nec tincidunt sodales.</p>
                    <h5>Source:</h5>
                    <p>Contact Center</p>
                    <h5>Created on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                    <label for="reward1" class="checkbox">
                        <input type="checkbox" id="reward1" value="reward4" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4>130</h4>
                    <p>Pellentesque rutrum lorem nec tincidunt sodales.</p>
                    <h5>Source:</h5>
                    <p>Contact Center</p>
                    <h5>Created on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                    <label for="reward2" class="checkbox">
                        <input type="checkbox" id="reward2" value="reward5" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4>150</h4>
                    <p>Pellentesque rutrum lorem nec tincidunt sodales.</p>
                    <h5>Source:</h5>
                    <p>Contact Center</p>
                    <h5>Created on:</h5>
                    <span class="date">03/07/22 | 04:37 pm</span>
                    <label for="reward3" class="checkbox">
                        <input type="checkbox" id="reward3" value="reward6" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                </NotesCard>
            </li>
        </ul>
        <div v-if="editNote" class="edit_modal">
            <div class="edit_section setting_wpr p-0">
                <div class="form_body">
                    <h2 class="title_header">Edit Points<button class="close_btn" @click="editNote = false"><i class="fas fa-times"></i></button></h2>
                    <div class="form_content">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Edit Points</label>
                                <div class="field_wpr">
                                    <input type="number" placeholder="140" >
                                </div>
                            </div>
                        </div>
                        <div class="action_wpr p-0">
                            <button class="btn cancel_btn" @click="editNote = false">Cancel</button>
                            <button class="btn save_btn">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="redeem" class="edit_modal">
            <div class="edit_section setting_wpr p-0">
                <div class="form_body">
                    <h2 class="title_header">Points Redeem<button class="close_btn" @click="redeem = false"><i class="fas fa-times"></i></button></h2>
                    <div class="form_content">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Points Redeemed</label>
                                <div class="field_wpr">
                                    <input type="number" placeholder="0" >
                                </div>
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Points Remaining</label>
                                <div class="field_wpr">
                                    <input type="number" placeholder="420" >
                                </div>
                            </div>
                        </div>
                        <label for="allow" class="switch_option capsule_btn">
                            <h5>Contact Notification<span>Praesent non lectus eu augue ornare faucibus. Sed porta id nisl id eleifend.</span></h5>
                            <input type="checkbox" id="allow" v-model="allowNotification" hidden>
                            <div><span></span></div>
                        </label>
                        <div v-if="allowNotification">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">You Just Redeem 0 Points for</label>
                                    <div class="field_wpr">
                                        <input type="text" placeholder="type here.." >
                                    </div>
                                </div>
                            </div>
                            <h3>Notification Method</h3>
                            <div class="edit_section">
                                <NotifyMethods/>
                            </div>
                        </div>
                        <div class="action_wpr p-0 mt-5">
                            <button class="btn cancel_btn" @click="redeem = false">Cancel</button>
                            <button class="btn save_btn">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pagination mt-2">
            <ul>
                <li>1</li>
                <li class="active">2</li>
                <li>3</li>
                <li>4</li>
                <li><i class="fas fa-ellipsis-h"></i></li>
            </ul>
        </div>
</template>

<script>
import PageItem from './PageItem.vue';
import NotesCard from './NotesCard.vue';
import NotifyMethods from './NotifyMethods.vue';
export default{
    name: 'ProfileReward',
    components:{
        PageItem,
        NotesCard,
        NotifyMethods
    },
    data(){
        return{
            editNote: false,
            redeem: false,
            allowNotification: false,
            deleteBox:[],
            actionObj: [
                {
                    icon: "fa-edit",
                    label: "Edit",
                }
            ]
        }
    },
    methods:{
        editFunc(){
            this.editNote = true;
        }
    }
}
</script>
