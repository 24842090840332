<template>
    <div class="page_item">
        <div class="form_grp m-0 p-0">
            <div class="group_item">
                <div class="field_wpr">
                    <Multiselect v-model="pageItem.value" v-bind="pageItem" class="small"></Multiselect>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
export default {
    name: 'PageItem',
    components:{
        Multiselect
    },
    data(){
        return{
            pageItem: {
                mode: 'single',
                value: ['1'],
                options: [
                    { value: '1', label: '6' },
                    { value: '2', label: '12' },
                    { value: '3', label: '24' },
                    { value: '4', label: '48' },
                    { value: '5', label: '96' }
                ]
            },
        }
    }
}
</script>
