<template>
    <div class="note_card">
        <div class="note_body">
            <slot></slot>
        </div>
        <div class="dropdown" @click="dropOption = !dropOption">
            <i class="fas fa-ellipsis-v"></i>
            <div class="drp_wrapper" v-if="dropOption" :class="{active : dropOption}">
                <ul>
                    <li v-for="items in action" :key="items.label" class="edit" v-on="perform ? { click: perform } : {}"><i :class="`fas ${items.icon} mr-1`"></i>{{items.label}}</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'NotesCard',
    props: ['action', 'perform'],
    data(){
        return{
            dropOption: false,
        }
    }
}
</script>
